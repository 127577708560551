<template>
  <div class="about">
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    name:'index'
  }
</script>
